<template>
  <div>
    <div v-if="!AddVisible && !EditVisible" class="view-wrap">
    <div class="button">
      <el-button type="primary" round @click="UpDate">
        <i class="el-icon-plus" /> 新增</el-button
      >
    </div>
    <div class="view">
      <SimpleTable
        :data="tableData"
        :tableFields="tableItem"
        :operation="operationEvent"
        :currentPage="searcForm.pageNum"
        :pageSize="searcForm.pageSize"
        :total="total"
        @sizeChange="changePageSize"
        @currentChange="changeCurrentPage"
      >
        <div slot="operation" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            content="修改"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="EditData(row.data)"
              ><i class="el-icon-edit"></i
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="row.data.status ? '启用' : '停用'"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="setting(row.data)"
              ><i
                :class="
                  row.data.status ? 'el-icon-video-play' : 'el-icon-video-pause'
                "
              ></i
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="重置密码"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="reset(row.data)"
              ><i class="el-icon-key"></i
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="row.data.contact ? '解绑手机' : '绑定手机'"
            placement="top-start"
          >
            <el-button
              type="text"
              :disabled="row.data.type !== '1'"
              @click="bindPhone(row.data)"
              ><i class="el-icon-mobile-phone"></i
            ></el-button>
          </el-tooltip>
        </div>
      </SimpleTable>
    </div>
    <miaDialog ref="changePwd" v-if="changePwdVisible">
      <div slot="content">
        <div class="changePwdtip">
          <p class="first">如何提高密码的安全性？</p>
          <p>密码长度必须为8-16位，且同时包含字母、数字</p>
          <p>避免使用过于简单的密码，例如123456、password</p>
          <p>避免使用人名或生日，例如zhangsan、zhangsan1990</p>
          <p>避免使用手机号码，例如135 6677 8899</p>
        </div>
        <el-form
          style="width: 90%"
          ref="changePwdForm"
          :model="changePwdForm"
          :rules="Pwdrules"
          label-width="120px"
        >
          <el-form-item label="新密码" prop="newPassword" key="newPassword">
            <el-input
              show-password
              v-model="changePwdForm.newPassword"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认密码"
            prop="repeatPassword"
            key="repeatPassword"
          >
            <el-input
              show-password
              v-model="changePwdForm.repeatPassword"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitData">提交</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
    <miaDialog ref="changePhone" v-if="changePhoneVisible">
      <div slot="content">
        <div class="changePwdtip" style="height: 50px">
          <p class="first">绑定手机后，员工可使用手机号码登录</p>
        </div>
        <el-form
          style="width: 90%"
          ref="changePhoneForm"
          :model="changePhoneForm"
          :rules="Phonerules"
          label-width="120px"
        >
          <el-form-item label="手机号码" prop="phone" key="phone">
            <el-input
              v-model="changePhoneForm.phone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认手机号码"
            prop="repeatPhone"
            key="repeatPhone"
          >
            <el-input
              v-model="changePhoneForm.repeatPhone"
              placeholder="请输入确认手机号码"
            ></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitPhoneData">提交</el-button>
            <el-button @click="changePhoneVisible = false">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
  </div>
  <AddStaff ref="AddStaff" v-if="AddVisible" @back="backList" @refresh="refresh"></AddStaff>
  <EditStaff ref="EditStaff" v-if="EditVisible" @back="backList" @refresh="refresh"></EditStaff>
  </div>
</template>
<script>
import {
  getUserList,
  changeEnAble,
  resetPassWord,
  resetPhone,
  unbindPhone,
} from "@/api/user";
import md5 from "md5";
import AddStaff from './staffForm.vue'
import EditStaff from './EditStaff.vue'
export default {
  components:{AddStaff,EditStaff},
  data() {
    const validatePwd = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请设置8-16位数字、字母组合的密码"));
        }
      } else {
        callback(new Error("请填写新密码"));
      }
    };
    const validateAffirmPwd = (rule, value, callback) => {
      if (
        this.changePwdForm.newPassword &&
        this.changePwdForm.newPassword !== value
      ) {
        callback(new Error("确认密码与新密码不一致"));
      } else {
        this.$refs["changePwdForm"].clearValidate("newPassword");
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
          this.verificationflag = true;
        } else {
          callback(new Error("请输入正确的手机号"));
          this.isShowCaptcha = false;
        }
      } else {
        callback(new Error("请填写手机号码"));
        this.isShowCaptcha = false;
      }
    };
    const validateAffirmPhone = (rule, value, callback) => {
      if (this.changePhoneForm.phone && this.changePhoneForm.phone !== value) {
        callback(new Error("确认手机号码和新的手机号码不一致"));
      } else {
        this.$refs["changePhoneForm"].clearValidate("phone");
        callback();
      }
    };
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      tableItem: [
        { prop: "name", label: "名称", type: "default" },
        { prop: "loginName", label: "登录名", type: "default" },
        { prop: "initialPassword", label: "初始化密码", type: "default" },
        {
          prop: "type",
          label: "类型",
          type: "userType",
          showTextList: ["自定义用户", "默认用户"],
        },
        {
          prop: "status",
          label: "状态",
          type: "tag",
          showTextList: ["停用", "启用"],
        },
        { prop: "roleList", label: "角色", type: "userRole" },
        { prop: "contact", label: "手机号", type: "default" },
        { label: "操作", type: "operation" },
      ],
      operationEvent: [],
      AddVisible:false,
      EditVisible:false,
      changePwdVisible: false,
      changePhoneVisible: false,
      changePwdForm: {
        userId: null,
        newPassword: "",
        repeatPassword: "",
      },
      changePhoneForm: {
        userId: null,
        phone: "",
        repeatPhone: "",
      },
      Pwdrules: {
        newPassword: [
          { required: true, message: "请填写新密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validatePwd },
        ],
        repeatPassword: [
          { required: true, message: "请填写确认密码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validateAffirmPwd },
        ],
      },
      Phonerules: {
        phone: [{ required: true, validator: validatePhone }],
        repeatPhone: [
          { required: true, message: "请填写确认手机号码", trigger: "blur" },
          { required: true, trigger: "blur", validator: validateAffirmPhone },
        ],
      },
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      getUserList(this.searcForm).then((res) => {
        const { records, current, total } = res.data;
        if (res.code === 1) {
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backList(){
      this.AddVisible = false
      this.EditVisible = false
    },
    refresh(){
      this.getData();
    },
    // 新增
    UpDate() {
      // this.$router.push({ path: "/system/staffForm" });
      this.AddVisible = true
    },
    // 编辑
    EditData(data) {
      this.EditVisible = true
      this.$nextTick(() => {
        this.$refs.EditStaff.init(data.id);
      });
      // this.$router.push({ path: "/system/EditStaff", query: { id: data.id } });
    },
    // 启用/停用
    setting(data) {
      this.$confirm(`是否${data.status ? "启用" : "停用"}该用户？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        changeEnAble(data.id, data.status).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 重置密码
    reset(data) {
      this.changePwdForm.userId = data.id;
      this.changePwdVisible = true;
      this.$nextTick(() => {
        this.$refs.changePwd.init("重置密码");
      });
    },
    // 绑定手机
    bindPhone(data) {
      if (data.contact) {
        this.$confirm(`是否解除手机绑定？`, "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          unbindPhone(data.id).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
            } else {
              this.$message.error(msg);
            }
            this.getData();
          });
        });
      } else {
        this.changePhoneForm.userId = data.id;
        this.changePhoneVisible = true;
        this.$nextTick(() => {
          this.$refs.changePhone.init("绑定手机");
        });
      }
    },
    submitData() {
      this.$refs["changePwdForm"].validate((valid) => {
        if (valid) {
          const form = {
            userId: this.changePwdForm.userId,
            newPassword: md5(this.changePwdForm.newPassword.toString()),
          };
          resetPassWord(form).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.getData();
              this.close();
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    close() {
      this.$refs.changePwd.close();
    },
    // 重置手机提交
    submitPhoneData() {
      this.$refs["changePhoneForm"].validate((valid) => {
        if (valid) {
          delete this.changePhoneForm.repeatPhone;
          resetPhone(this.changePhoneForm).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.getData();
              this.closePhone();
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    closePhone() {
      this.changePhoneForm = {
        userId: null,
        phone: "",
        repeatPhone: "",
      };
      this.$refs.changePhone.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.changePwdtip {
  width: 80%;
  padding: 5%;
  padding-bottom: 0%;
  height: 130px;
  margin: 0 auto;
  background: #eff6fe;
  border: 1px solid #cedbef;
  border-radius: 4px;
  margin-bottom: 20px;
  .first {
    font-size: 16px;
    color: #187ae8;
    margin-bottom: 5px;
  }
}
</style>