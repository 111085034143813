<template>
  <div class="FormWrap" v-loading="loading">
    <mia-navback @back="back" :isShowSave="true" @submit="submitForm" />
    <div class="content">
      <mia-Card title="基础信息">
        <div style="margin-left: 4px; width: 50%" slot="content">
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="用户名称" prop="name" key="name">
              <el-input
                :disabled="forbid"
                v-model="form.name"
                placeholder="请输入用户名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="loginName" key="loginName">
              <el-input
                v-model="form.loginName"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="!form.id"
              label="登录密码"
              prop="password"
              key="password"
            >
              <el-input
                v-model="form.password"
                placeholder="请输入登录密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="roleIds" key="roleIds">
              <el-select
                style="width: 100%"
                v-model="roleIds"
                multiple
                placeholder="请选择角色"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.isAuth === '0'"
                >
                <span style="float: left">{{ item.name }}</span>
                <span v-if="item.isAuth === '0'">（未授权）</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号" prop="contact" key="contact">
              <el-input
                v-model="form.contact"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status" key="status">
              <el-switch v-model="form.status" active="#13ce66"> </el-switch>
            </el-form-item>
            <el-form-item label="允许登陆" prop="allow" key="allow">
              <el-switch v-model="form.allow" active="#13ce66"> </el-switch>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card>
    </div>
  </div>
</template>
<script>
import { getRoleList } from "@/api/role";
import { getUserList, save } from "@/api/user";
import md5 from 'md5'
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的手机号"));
        }
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      const reg = /^[A-Za-z0-9]{6,12}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入6-12位数字或字母的用户名"));
        }
      } else {
        callback("请输入用户名");
      }
    };
    const validatePwd = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请设置8-16位数字、字母组合的密码"));
        }
      } else {
        callback();
      }
    };
    return {
      loading: false,
      forbid: false,
      form: {
        id: null,
        name: "",
        loginName: "",
        roleIds: [],
        contact: "",
        // 0 启用 1禁用
        status: null,
        password: "",
        allow:null,
      },
      roleIds: null,
      rules: {
        name: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        loginName: [
          { required: true, validator: validateName, trigger: "blur" },
        ],
        roleIds: [{ required: true, message: "请选择角色", trigger: "change" }],
        contact: [{ validator: validatePhone, trigger: "blur" }],
        password: [{ trigger: "blur", validator: validatePwd }],
        status: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        allow: [
          { required: true, message: "请选择登录状态", trigger: "change" },
        ],
      },
      roleList: [],
    };
  },
  watch: {
    roleIds(val) {
      this.form.roleIds = val;
    },
  },
  created() {
    this.form = {
      id: null,
      name: "",
      loginName: "",
      roleIds: [],
      contact: "",
      // 0 启用 1禁用
      status: true,
      password: "",
      // 0 允许登录 1不允许登录
      allow: true
    };
    this.RoleList();
  },
  mounted(){
      this.$refs["form"].resetFields()
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
            const form = JSON.parse(JSON.stringify(this.form))
            form.status = this.form.status ? 0 : 1;
            form.allow = this.form.allow ? 0 : 1;
            form.password = form.password !== '' ? md5(form.password) : ''
          save(form).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.$emit("back")
              this.$emit('refresh')
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    getData() {
      getUserList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          const data = records.filter((val) => {
            return val.id === this.form.id;
          });
          this.form = { ...data[0] };
          this.roleIds = data[0].roleList;
          this.form.status = data[0].status ? false : true;
          this.form.allow = data[0].allow ? false : true;
          this.forbid = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    RoleList() {
      getRoleList().then((res) => {
        const { records } = res.data;
        if (res.code === 1) {
          this.roleList = records;
          if (this.form.id) {
            this.getData();
          }
        } else {
          this.roleList = [];
        }
      });
    },
    back(){
            this.$emit('back')
    }
  },
};
</script>